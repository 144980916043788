@import "../../../../public/assets/scss/default/variables";

.policy-hero {
    background-color: $theme-color;
    top: 100px;
  }

  .policy-hero::before {
    background-image: url(/assets/images/pattern-5.png);
  }

  .horizontal-logo {
    @media (min-width: 1200px) and (max-width: 1420px), (max-width: 420px) {
        display: none;
    }
}

.octopus-logo {
    width: 120px !important;
    @media (min-width: 1420px), (max-width: 1199px)  {
        display: none;
    }
    @media (min-width: 1200px) and (max-width: 1420px) {
        display: block;
    }
    @media (max-width: 420px) {
        display: block;
        width: 70px !important;
    }
}

