/*====================
Mainmenu Area 
=====================*/

.active-main-nav {
  border-bottom: 3px solid $white;
  padding-bottom: 5px !important;
  font-weight: 600 !important;
}
.active-sub-nav {
  color: $theme-color !important;
  font-weight: 900 !important;
  @media #{$lg-layout}, #{$md-layout}, #{$sm-layout} {
    border-bottom: 3px solid $white;
    color: $white !important;
  }
}

.mainmenunav {
  ul {
    &.mainmenu {
      display: flex;
      justify-content: flex-end;
      @extend %liststyle;
      flex-wrap: wrap;
      text-transform: uppercase;

      > li {
        margin: 0 15px;
        position: relative;
        > a {
          @extend %transition;
          color: #c6c9d8;
          font-size: 16px;
          font-weight: 500;
          padding: 20px 0;
          display: inline-block;
        }
        &.active-nav {
          color: red;
        }
        > ul {
          &.submenu {
            @extend %liststyle;
            min-width: 240px;
            height: auto;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 90;
            opacity: 0;
            visibility: hidden;
            background: #c9d1d3; //C8C6C6
            box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
            text-align: left;
            @extend %transition;
            padding: 12px 0;
            // border-radius: 4px;
            li {
              position: relative;
              a {
                font-size: 16px;
                font-weight: 500;
                padding: 5px 20px;
                font-size: 14px;
                display: block;
                color: #1f1f25;
                margin: 0 10px;
                border-radius: 3px;
                @extend %transition;
              }
              &:hover {
                > a {
                  color: $white;
                  font-weight: 900;
                  // background: rgba(31, 65, 139, 0.3);
                }
              }

              .submenu {
                @extend %liststyle;
                min-width: 240px;
                height: auto;
                position: absolute;
                top: 0;
                left: 100%;
                z-index: 90;
                opacity: 0;
                visibility: hidden;
                background-color: #fff;
                box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
                text-align: left;
                @extend %transition;
                padding: 12px 0;
                border-radius: 4px;
              }

              &:hover {
                .submenu {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }
        &:hover {
          > ul {
            &.submenu {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        &:hover {
          // Main Nav Font Styling for Hover w/ Subnav
          > a {
            color: white;
            font-weight: 700;
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.humberger-menu {
  span {
    &.text-white {
      color: #c6c9d8 !important;
    }
  }
}

.color-white {
  .mainmenunav {
    ul {
      &.mainmenu {
        @extend %liststyle;
        > li {
          > a {
            color: #ffffff;
          }
          &:hover {
            > a {
              color: rgba(255, 255, 255, 0.6);
              @media #{$md-layout} {
                color: $theme-color;
              }
              @media #{$sm-layout} {
                color: $theme-color;
              }
            }
          }
        }
      }
    }
  }
}

.color-black {
  .mainmenunav {
    ul {
      &.mainmenu {
        @extend %liststyle;
        > li {
          > a {
            color: rgba(29, 29, 36, 1);
          }
          &:hover {
            > a {
              color: $theme-color;
            }
          }
        }
      }
    }
  }
}

@media #{$lg-layout}, #{$md-layout}, #{$sm-layout} {
  .header-style-two .humberger-menu span.text-white {
    color: #1d1d24 !important;
  }
}

// @media #{$md-layout}{
//     .header-style-two .humberger-menu span.text-white{
//         color: #1d1d24 !important;
//     }
// }

// @media #{$sm-layout}{
//     .header-style-two .humberger-menu span.text-white{
//         color: #1d1d24 !important;
//     }
// }
