@import "../../../public/assets/scss/default/variables";

// .horizontal-logo {
//     @media (min-width: 1200px) and (max-width: 1420px), (max-width: 420px) {
//         display: none;
//     }
// }

.octopus-logo {
    width: 120px !important;
    // @media (min-width: 1420px), (max-width: 1199px)  {
    //     display: none;
    // }
    // @media (min-width: 1200px) and (max-width: 1420px) {
    //     display: block;
    // }
    @media (max-width: 420px) {
        // display: block;
        width: 70px !important;
    }
}