@import "../../../public/assets/scss/default/variables";

.category-heading {
  font-size: 10px;
}

.social-share-inner {
  margin: 0 15px 0 25px;
}

@media #{$sm-layout} {
    .social-share-inner {
        display: none;
    }
}