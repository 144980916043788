@import "../../../public/assets/scss/default/variables";

.parallax-bg-image {
    height: auto;
    width: 100%
}

.parallax-logo {
    margin-bottom: 90px;
    width: 450px;

}

.parallax-text {
    font-weight: 500;
    font-size: 45px;
}