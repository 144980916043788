@import "../../public/assets/scss/default/variables";

// .schedule .date-nav {
//   background: $main-blue;
// }

// .background-logo {
//   // content: "";
//   // position: absolute;
//   // z-index: -1;
//   background: url(/assets/images/logo/leverage-brazilian-jiu-jitsu-trident-logo-black.png) no-repeat;
//   // transform: rotate(-45deg);
// }

@media print {
  body * {
    visibility: hidden;
  }
  #gym-schedule * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
  @page {
    size: landscape;
  }
}

#body .schedule {
  background-color: rgba(255,255,255,.9);
}
