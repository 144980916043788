//===== Colors =====//
$main-blue: #183c94; // darker- #1f418b lighter- #2e4c95 #183c94
$theme-color: $main-blue;
$theme-color-2: #c9d1d3; // Not using at all yet
$theme-color-3: #f61b10; // Only used for gradient for services. Take away?
$theme-color-4: #ef0963; // Only used for gradient for services. Take away?
$body-color: #1d1d24;
$heading-color: $main-blue;
$border-color: #d4d4d4;
$white:#fff;


/*===============================
    Font Family 
=================================*/
$body-font: 'RedHatText', sans-serif;
$heading-font: 'RedHatDisplay', sans-serif;


// Heading Font List
$font-1: $heading-font;

$fontList: $font-1;


/*===============================
    Color Variation 
=================================*/
$color-1:  $theme-color;
$color-2:  #f4769a;
$color-3:  #2f21b3;
$color-4:  #8956e2;
$colorList: $color-1, $color-2 ,$color-3 ,$color-4;


/*===============================
    Border Radius
=================================*/

$button-border-radius: 0;
$component-border-radius: 0;
$image-border-radius: 0;

//===== Line Height =====//
$body-line-height: 1.714286;
//==== Font size =====//
$body-font-size: 14px;
$h1: 64px;
$h2: 54px;
$h3: 24px;
$h4: 20px;
$h5: 18px;
$h6: 16px;

//===== Social Colors =====//
$facebook : #3B5998;
$twitter : #00ACEE;
$google-plus : #DD4B39;
$pinterest : #C8232C;
$linkedin : #0E76A8;
$flickr : #FF0084;
$google : #dd4b39;
$youtube : #cd201f;




// Layouts
$smlg-device: 'only screen and (max-width: 1199px)'; // Up to my laptop size

$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)'; // My / large monitors

$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)'; // Laptops
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)'; // Tablets
$sm-layout:'only screen and (max-width: 767px)'; // Up to tablet sizes
$large-mobile: 'only screen and (max-width: 575px)'; // Large phone
/* container 450px*/
$small-mobile: 'only screen and (max-width: 479px)'; // Small Phone
/* container 300px*/

@media #{$large-mobile} {
    .container {
        width: 450px;
    }
}

@media #{$small-mobile} {
    .container {
        width: 320px;
    }
}

// For Imroz Footer

%transition {
    -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
    transition: all .3s cubic-bezier(.645,.045,.355,1);
}