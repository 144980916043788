@import "../../../public/assets/scss/default/variables";
// @import '../../assets/scss/variables';

.booking-widget {
  padding-top: 10%;
}

.column-title {
  font-size: 36px;
}
.contact-form {
  padding-top: 3%;
}
.wrapper {
  border: 1px solid $theme-color;
  margin-bottom: 50px;
  padding-top: 50px;
  width: 90%;

  &.left {
    margin-right: 5px;
  }
  &.right {
    margin-left: 5px;
  }
}
