#content {
  background-image: url(/assets/images/logo/leverage-brazilian-jiu-jitsu-octopus-logo-black-transparent.png);
  background-position: top 10% right -15%;
  background-repeat: repeat;
  background-size: 50%;
}

@media #{$large-mobile} {
  #content {
    background-position: top 10% center;
    background-repeat: repeat-y;
    background-size: 120%;
  }
}
// Typography
// p copy on trial page. Style taken from general p copy on site
.main-copy {
  color: #717173;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  padding: 0 21%;

&__wide {
    padding-left: 0 13%;
}  
}

// 2 titles for the gymdesk sections

// Formatting
.center {
  text-align: center;
}
